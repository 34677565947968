<template>
    <div>
        <b-row class="justify-content-md-center mt-5">
            <b-col lg="6" class="text-center">
                <b-row class="justify-content-md-center">
                    <b-col> {{ $t("not-valid-user.title") }}</b-col>
                </b-row>
                <b-row class="justify-content-md-center">
                    <b-col> {{ $t("not-valid-user.body") }}</b-col>
                </b-row>
                <b-row class="justify-content-md-center">
                    <b-col lg="3" class="m-2">
                        <b-button class="main-btn" @click="goToPanamaDigital">
                            {{ $t("not-valid-user.panama-digital") }}
                        </b-button>
                    </b-col>
                    <b-col lg="3" class="m-2">
                        <b-button class="main-btn" @click="goToLogout">
                            {{ $t("not-valid-user.logout") }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "not-valid-user",
    methods: {
        goToPanamaDigital() {
            window.location.href = "https://www.panamadigital.gob.pa/";
        },
        goToLogout() {
            this.$router.push({
                name: "logout",
            });
        },
    },
};
</script>